import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { TrademarkSpan } from "../../components/header-image/styled-components";
import {
  CopywriteContainer,
  MainContainer,
  NFTImageContainer,
  Image,
  TextTrademarkSpan,
} from "../../components/nft-info-card/styled-components";

const LinkButton = styled(Button)`
  display: block !important;
  margin: 0 auto !important;
  background-color: var(--main-button-background) !important;
  min-width: 120px !important;
  font-size: 1em !important;
`;

export const LinkStyled = styled(Link)`
  color: white;
  text-decoration: none;
  pointer: cursor;
  padding: 30px;
`;

export const HeadingTrademarkSpan = styled.span
`
  position: relative;
  top: -18px;
  font-size: 0.5em !important;
`

interface LandingPageAuctionCardProps {
  index: number;
}

const LandingPageAuctionCard = (props: LandingPageAuctionCardProps) => {
  return (
    <main>
      <MainContainer
        style={{
          flexDirection: props.index % 2 == 0 ? "row" : "row-reverse",
        }}
      >
        <NFTImageContainer>
          <Image
            placeholder="special-luna-image.png"
            src="FOREO_LUNA_4_special.gif"
            alt="NFT To Mint"
          />
        </NFTImageContainer>
        <CopywriteContainer style={{ marginTop: 60 }}>
          <h1>Mariam's LUNA<HeadingTrademarkSpan>&trade;</HeadingTrademarkSpan> 4</h1>
            <p style={{ textAlign: "center", padding: 40 }}>
              As a passionate photographer and videographer, <a href="https://twitter.com/mariamsekh" target="_blank">Mariam</a> joined our
              cause by designing a very special NFT aiming it will help raise
              funds to aid those struggling with mental health as well as
              various skin conditions that affect it just as well.
            </p>
          <LinkButton>
            <LinkStyled to="auction">BID HERE</LinkStyled>
          </LinkButton>
        </CopywriteContainer>
      </MainContainer>
    </main>
  );
};

export default LandingPageAuctionCard;
