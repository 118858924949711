import { ImageAbsolute, StyledHeaderImage, TextAbsoluteOne, TextAbsoluteTwo, TrademarkSpan } from "./styled-components";

const HeaderImage = () => {
  return (
    <StyledHeaderImage>
      <img
        style={{ minWidth: "100%", objectFit: "cover" }}
        src="landing-header.jpg"
      />
      <TextAbsoluteOne>NFTs,</TextAbsoluteOne>
      <TextAbsoluteTwo>meet LUNA<TrademarkSpan>&trade;</TrademarkSpan> 4</TextAbsoluteTwo>
    </StyledHeaderImage>
  );
};

export default HeaderImage;
