import React, { useState } from "react";
import BalanceContext from "../../contexts/BalanceContext";

const BalanceContextProvider: React.FC = ({ children }) => {
  const updateBalance = (newBalance: number) => {
    setBalanceState({
      balance: newBalance,
      updateBalance,
    });
  };

  const [balanceState, setBalanceState] = useState({
    balance: 0,
    updateBalance,
  });

  return (
    <BalanceContext.Provider value={balanceState}>
      {children}
    </BalanceContext.Provider>
  );
};

export default BalanceContextProvider;
