import styled from "styled-components";
import { TextTrademarkSpan } from "../../components/nft-info-card/styled-components";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 10% 5% 10%;
  text-align: center;
  background-color: white;
`;

const HeaderText = () => {
  return (
    <TitleContainer>
      <h1 style={{ paddingBottom: "30px", textAlign: "center" }}>Bigger than skincare</h1>
      <p style={{ paddingBottom: "30px" }}>
        Always ahead of its time, LUNA<TextTrademarkSpan>&trade;</TextTrademarkSpan> 4 is about to become the 1st skincare
        product to ever make its debut in virtual reality - this time in form of
        an NFT.
      </p>
      <p>
        It looks great on Instagram but life behind filters carries a number of
        skin conditions that often result in serious mental health disorders.
        Inspired by the rise of a new type of beauty, our mission is to use NFTs
        to raise funds for a selection of charities that focus on said issues.
        All the profits from this NFT sale will be donated to these charities.
        Bid on the special or buy one of the LUNA<TextTrademarkSpan>&trade;</TextTrademarkSpan> NFTs to help a good cause,
        get your digital LUNA<TextTrademarkSpan>&trade;</TextTrademarkSpan> 4, and enter the pool for future rewards.
      </p>
    </TitleContainer>
  );
};

export default HeaderText;
