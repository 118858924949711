import { LinearProgress, Paper } from "@material-ui/core";
import styled from "styled-components";

export const NFTImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items:center;
  background-color: inherit;
`;

export const CopywriteContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Card = styled(Paper)`
  display: inline-block;
  background-color: var(--countdown-background-color) !important;
  margin: 5px;
  min-width: 40px;
  padding: 24px;

  h1 {
    margin: 0px;
  }
`;

export const MintButtonContainer = styled.div`
  margin: auto;

  button.MuiButton-contained:not(.MuiButton-containedPrimary).Mui-disabled {
    color: #464646;
  }

  button.MuiButton-contained:not(.MuiButton-containedPrimary):hover,
  button.MuiButton-contained:not(.MuiButton-containedPrimary):focus {
    -webkit-animation: pulse 1s;
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(255, 255, 255, 0);
  }

  @-webkit-keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #ef8f6e;
    }
  }
`;

export const BorderLinearProgress = styled(LinearProgress)`
  margin: 20px;
  height: 10px !important;
  border-radius: 30px;
  border: 2px solid white;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.5);
  background-color: var(--main-text-color) !important;
  > div.MuiLinearProgress-barColorPrimary {
    background-color: var(--title-text-color) !important;
  }
  > div.MuiLinearProgress-bar1Determinate {
    border-radius: 30px !important;
    background-image: linear-gradient(270deg, rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.5));
  }
`;

export const SolExplorerLink = styled.a`
  color: var(--title-text-color);
  font-weight: bold;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  outline: none;
  text-decoration: none;
  text-size-adjust: 100%;
`;

export const MainContainer = styled.div`
  display: flex;
  margin-top: 10%;
  margin-bottom: 20px;
  margin-right: 4%;
  margin-left: 4%;
  text-align: center;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    flex-direction: column !important;
  }
`;

export const Image = styled.img`
  border-radius: 7px;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.5);

  width: 100%;
  height: auto;
`;

export const Video = styled.video`
  border-radius: 7px;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.5);

  width: 100%;
  height: auto;
`;

export const TextTrademarkSpan = styled.span
    `
    position: relative;
    top: -9px;
    font-size: 0.7em !important;

    @media (max-width: 1550px) {
      top: -8px;
    }

    @media (max-width: 1000px) {
      top: -7px;
    }

    @media (max-width: 600px) {
        top: -5px;
    }
`
