import React, { useState } from "react";

import { AnchorWallet } from "@solana/wallet-adapter-react";
import { web3, BN } from "@project-serum/anchor";
import { Modal } from "../Modal";

import { AuctionModalConfirmed } from "./AuctionModalConfirmed";

import { TransactionState } from "../../model";
import { CandyShop } from "../../../candy-shop-sdk/src";
import { Auction } from "@liqnft/candy-shop-types";

import { notification, NotificationType } from "../../utils/rc-notification";
import { AuctionModalDetail } from "./AuctionModalDetail";
import { CircularProgress } from "@material-ui/core";

const Logger = "CandyShopUI/AuctionModal";

export interface AuctionModalProps {
  auction: Auction;
  onClose: () => void;
  wallet: AnchorWallet | undefined;
  walletConnectComponent: React.ReactElement;
  candyShop: CandyShop;
  isOpen: boolean;
  opacity: number;
}

enum TitleTextType {
  BID_CONFIRMED = "Bid Confirmed",
  TRANSACTION_CONFIRMED = "Transaction Confirmed",
  WITHDRAWAL_CONFIRMED = "Withdrawal Confirmed",
}

export const AuctionModal: React.FC<AuctionModalProps> = ({
  auction,
  onClose,
  wallet,
  walletConnectComponent,
  candyShop,
  isOpen,
  opacity,
}) => {
  const [state, setState] = useState<TransactionState>(
    TransactionState.DISPLAY
  );
  const [titleText, setTitleText] = useState<TitleTextType>(
    TitleTextType.TRANSACTION_CONFIRMED
  );
  const [bidPrice, setBidPrice] = useState<number>();

  const placeBid = (price: number) => {
    if (!wallet) return;

    const minBidPrice =
      (auction.highestBidPrice
        ? Number(auction.highestBidPrice) + Number(auction.tickSize)
        : Number(auction.startingBid)) / candyShop.baseUnitsPerCurrency;

    if (price < minBidPrice) {
      return notification(
        `You must bid at least ${minBidPrice}`,
        NotificationType.Error
      );
    }

    setState(TransactionState.PROCESSING);

    console.log(wallet)

    candyShop
      .bidAuction({
        wallet,
        tokenMint: new web3.PublicKey(auction.tokenMint),
        tokenAccount: new web3.PublicKey(auction.tokenAccount),
        bidPrice: new BN(price * candyShop.baseUnitsPerCurrency),
      })
      .then((txId: string) => {
        console.log(`${Logger}: bidAuction request success, txId=`, txId);
        setTitleText(TitleTextType.BID_CONFIRMED);
        setState(TransactionState.CONFIRMED);
        setBidPrice(price);
      })
      .catch((err: Error) => {
        notification(err.message, NotificationType.Error);
        console.log(`${Logger} bidAuction failed, error=`, err);
        setState(TransactionState.DISPLAY);
      });
  };

  const withdraw = () => {
    if (!wallet) return;

    setState(TransactionState.PROCESSING);
    candyShop
      .withdrawAuctionBid({
        wallet,
        tokenMint: new web3.PublicKey(auction.tokenMint),
        tokenAccount: new web3.PublicKey(auction.tokenAccount),
      })
      .then((txId: string) => {
        console.log(
          `${Logger}: withdrawAuctionBid request success, txId=`,
          txId
        );
        setTitleText(TitleTextType.WITHDRAWAL_CONFIRMED);
        setState(TransactionState.CONFIRMED);
      })
      .catch((err: Error) => {
        notification(err.message, NotificationType.Error);
        console.log(`${Logger} withdrawAuctionBid failed, error=`, err);
        setState(TransactionState.DISPLAY);
      });
  };

  return (
    <Modal isOpen={isOpen} opacity={opacity} onCancel={onClose}>
      {state === TransactionState.DISPLAY && (
        <AuctionModalDetail
          auction={auction}
          placeBid={placeBid}
          onWithdrew={withdraw}
          onClose={onClose}
          walletPublicKey={wallet?.publicKey}
          walletConnectComponent={walletConnectComponent}
          candyShop={candyShop}
        />
      )}
      {state === TransactionState.PROCESSING && <CircularProgress />}
      {state === TransactionState.CONFIRMED && wallet && (
        <AuctionModalConfirmed
          titleText={titleText}
          onClose={() => {
            setState(TransactionState.DISPLAY);
            onClose();
          }}
          descriptionText={
            bidPrice ? `${bidPrice} ${candyShop.currencySymbol}` : undefined
          }
        />
      )}
    </Modal>
  );
};
