import {
  Box,
  Container,
  CopyText,
  DiscordIcon,
  InstagramIcon,
  NestedRow,
  Row,
  SocialIcon,
  TwitterIcon,
} from "./styled-components";

const iconSize = 48;

const Footer = () => {
  return (
    <Box>
      <Container>
        <Row>
          <CopyText>Join us on:</CopyText>
          <NestedRow>
            <SocialIcon href="https://foreo.se/kqw6" target={"_blank"}>
              <DiscordIcon size={iconSize} />
            </SocialIcon>
            <SocialIcon href="https://foreo.se/6m5y" target={"_blank"}>
              <InstagramIcon size={iconSize} />
            </SocialIcon>
            <SocialIcon href="https://foreo.se/5vi9" target={"_blank"}>
              <TwitterIcon size={iconSize} />
            </SocialIcon>
          </NestedRow>
          <SocialIcon
              style={{ backgroundColor: "white", padding: "0", borderRadius: "16px" }}
              href="https://candy.liqnft.com/?utm_source=CandyShops&utm_medium=Button&utm_campaign=Site&utm_id=CandyShop"
              target={"_blank"}
            >
              <img
                width={160}
                src="Powered_by_CandyShop_purple_bg.png"
              />
            </SocialIcon>
        </Row>
      </Container>
    </Box>
  );
};
export default Footer;
