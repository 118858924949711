import * as anchor from "@project-serum/anchor";

export const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
    try {
      const candyMachineId = new anchor.web3.PublicKey(
        process.env.REACT_APP_CANDY_MACHINE_ID!
      );

      return candyMachineId;
    } catch (e) {
      console.log("Failed to construct CandyMachineId", e);
      return undefined;
    }
  };
