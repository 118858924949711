import styled from "styled-components";
import Modal from "styled-react-modal"

export const CandyModal = Modal.styled
  `
  background-color: white;
  transition : all 0.3s ease-in-out;
  border-radius: 16px;
  overflow-y: hidden;
`;


export const CandyModalContent = styled.div
  ` display:flex;
    flex-direction: row;
    overflow-y: hidden;
    color: black;
    border-radius: 16px;
    border: 2px solid #000;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    @media (max-width: 600px) {
      padding: 30px 40px 10px;
    }
    max-height: 80vh;
    padding: 20px;
    padding-top: 20px;
`;

export const CandyModalContentCloseButtonContainer = styled.div
  `
  height: 40px;
  width: 100%;
  display:flex;
`;
