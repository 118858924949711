import React, { useState, useEffect, useCallback, useRef } from "react";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import {
  CandyShop,
  fetchAuctionsByShopAddress,
} from "../../external/liqnft/candy-shop-sdk/src";
import {
  Auction,
  AuctionStatus,
  ListBase,
  ShopStatusType,
} from "@liqnft/candy-shop-types";
import {
  AuctionActionsStatus,
  DEFAULT_LIST_AUCTION_STATUS,
} from "../../external/liqnft/candy-shop/constant";
import { AuctionCard } from "../../external/liqnft/candy-shop/components/AuctionCard";
import { Empty } from "../../external/liqnft/candy-shop/components/Empty";
import { useValidateStatus } from "../../external/liqnft/candy-shop/hooks/useValidateStatus";
import { useUpdateSubject } from "../../external/liqnft/candy-shop/contexts/CandyShopDataValidator";
import { CandyContainer } from "./styled-components";
import { CircularProgress } from "@material-ui/core";

const Logger = "CandyShopUI/Auctions";

interface AuctionsProps {
  wallet?: AnchorWallet;
  walletConnectComponent: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  candyShop: CandyShop;
  statusFilters?: AuctionStatus[];
}

export const AuctionsOverride: React.FC<AuctionsProps> = ({
  walletConnectComponent,
  wallet,
  candyShop,
  statusFilters = DEFAULT_LIST_AUCTION_STATUS,
}) => {
  const [auctionedNfts, setAuctionedNfts] = useState<Auction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useUpdateSubject({
    subject: ShopStatusType.Auction,
    candyShopAddress: candyShop.candyShopAddress,
  });
  const updateStatus = useValidateStatus(AuctionActionsStatus);
  const updateStatusRef = useRef<number>(updateStatus);

  const walletKeyString = wallet?.publicKey.toString();

  const fetchAuctions = useCallback(() => {
    setLoading(true);
    fetchAuctionsByShopAddress(candyShop.candyShopAddress, {
      limit: 1,
      status: statusFilters,
      walletAddress: walletKeyString,
    })
      .then((data: ListBase<Auction>) => {
        if (!data.success || !data.result?.length) {
          return;
        }
        setAuctionedNfts(data.result);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [candyShop.candyShopAddress, statusFilters, walletKeyString]);

  useEffect(() => {
    if (updateStatus === updateStatusRef.current) return;
    updateStatusRef.current = updateStatus;
    fetchAuctionsByShopAddress(candyShop.candyShopAddress.toString(), {
      limit: 1,
      status: statusFilters,
      walletAddress: walletKeyString,
    })
      .then((response: ListBase<Auction>) => {
        const memo: { [key: string]: true } = {};

        setAuctionedNfts(response.result);
      })
      .catch((err: any) => {
        console.log(`${Logger} BackgroundUpdate failed, error=`, err);
      });
  }, [
    candyShop.candyShopAddress,
    statusFilters,
    updateStatus,
    walletKeyString,
  ]);

  useEffect(() => {
    fetchAuctions();
  }, []);

  return (
    <CandyContainer>
      {!wallet && walletConnectComponent}
      {wallet && (
        <>
          {loading && <CircularProgress />}
          {!loading && (
            <>
              {auctionedNfts.length === 0 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h2 style={{ textAlign: "center" }}>Preparing auction.</h2>
                  <h3 style={{ textAlign: "center" }}>
                    Come again a bit later.
                  </h3>
                </div>
              )}

              {auctionedNfts.length > 0 && (
                <AuctionCard
                  key={auctionedNfts[0].tokenAccount}
                  auction={auctionedNfts[0]}
                  candyShop={candyShop}
                  wallet={wallet}
                  walletConnectComponent={walletConnectComponent}
                />
              )}
            </>
          )}
        </>
      )}
    </CandyContainer>
  );
};
