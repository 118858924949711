import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import styled from "styled-components";

export const WalletContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const WalletAmount = styled.div`
  color: black;
  width: auto;
  display:flex;
  padding: 5px 5px 5px 5px;
  min-width: 48px;
  min-height: auto;
  border-radius: 22px;
  background-color: var(--wallet-amount-background);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 500;
  text-transform: uppercase;
  justify-content:center;
  align-items: center;

  font-size: 0.75em;

  @media (max-width: 700px) {
    margin-top: 60px;
  }
`;

export const Wallet = styled.div
`
  margin: auto;
  `;

export const ConnectButton = styled(WalletMultiButton)`
  border-radius: 18px !important;
  padding: 6px 16px;
  background-color: var(--wallet-connect-button-background) !important;
  margin: 0 auto;
`;

export const ConnectButtonSecondary = styled(WalletMultiButton)
`
  padding: 30px;
  background-color: var(--main-button-background) !important;
  margin: 0 auto;
  font-size: 1em !important;
`
