import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  extendNavbar: boolean
}

export const NavbarContainer = styled.nav<Props>`
  width: 100%;
  height: ${(props) => (props.extendNavbar ? "100vh" : "80px")};
  background-color: white;
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    height: 80px;
  }
  text-color: black;
  position: fixed;
  top: 0;
  z-index: 10;
`;

export const FillContainer = styled.div
`
  flex: 3;
  display: flex;

  @media (min-width: 1000px) {
    display: none;
  }
`

export const CenterContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 1000px) {
    flex: 6;
    justify-content: center;
  }
`;

export const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 50px;

  @media (max-width: 1000px) {
    flex: 3;
    padding-left: 0px;
  }
`;

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  @media (max-width: 1000px) {
    font-size: 12px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`;

export const NavbarLinkContainer = styled.div`
  display: flex;
  gap: 20px;
`;

export const BaseNavbarLink = styled(Link)
  `
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px;
`;

export const NavbarLink = styled(BaseNavbarLink)
  `
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const NavbarLinkExtended = styled(BaseNavbarLink)`
  margin-top: 40px;
  font-size: 1.4em;
`;

export const Logo = styled.img`
  margin: 10px;
  max-width: 180px;
  height: auto;

  @media (max-width: 1000px) {
    max-width: 120px;
  }
`;

export const OpenLinksButton = styled.button`
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  color: black;
  font-size: 45px;
  cursor: pointer;
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1001px) {
    display: none;
  }
`;
