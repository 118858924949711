import styled from "styled-components";

export const StyledHeaderImage = styled.div`
    height: 80vh;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    margin-top: 40px;

    @media screen and (max-width: 1550px) {
        height: 60vh;
    }

    @media screen and (max-width: 992px) {
        height: 40vh;
    }

    @media screen and (max-width: 600px) {
        height: 30vh;
        margin-top: 60px;
    }
`;

export const ImageAbsolute = styled.img
    `
    position: absolute;
    top: 10%;
    max-width: 200px;
    max-height: 180px;

    @media (max-width: 1000px) {
        max-height: 100px;
        max-width: 120px;
        top: 15%;
    }
`;

export const TextAbsoluteOne = styled.h2
    `
    position: absolute;
    bottom: 12%;
    font-size: 2.5em;

    @media (max-width: 1550px) {
        font-size: 1.8em;
        padding: 8px;
        bottom: 10%;
    }

    @media (max-width: 1000px) {
        font-size: 1.4em;
    }

    @media (max-width: 600px) {
        font-size: 1em;
    }
`

export const TextAbsoluteTwo = styled.h2
    `
    position: absolute;
    bottom: 2%;
    font-size: 2.5em;
    margin-top: 15px;

    @media (max-width: 1550px){
        bottom: 1%;
        font-size: 1.8em;
    }

    @media (max-width: 1000px) {
        font-size: 1.4em;
    }

    @media (max-width: 1000px) {
        font-size: 1.4em;
        bottom: 0%;
    }

    @media (max-width: 600px) {
        font-size: 1em;
        bottom: 0%;
    }
`

export const TrademarkSpan = styled.span
    `
    position: relative;
    top: -22px;
    font-size: 0.5em !important;

    @media (max-width: 1550px) {
        top: -16px;
    }

    @media (max-width: 1000px) {
        top: -12px;
    }

    @media (max-width: 600px) {
        top: -8px;
    }
`
