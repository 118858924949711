import React from "react";

import {
  CandyAuctionModalConfirmed,
  CandyAuctionModalConfirmedDescription,
  CandyAuctionModalConfirmedHeader,
  CandyAuctionModalNoticeCandyButton,
} from "./styled-components";
import { Tick } from "styled-icons/typicons";

interface AuctionModalConfirmedProps {
  titleText: string;
  descriptionText?: string;
  onClose: () => void;
}

export const AuctionModalConfirmed: React.FC<AuctionModalConfirmedProps> = ({
  titleText,
  descriptionText,
  onClose,
}) => {
  return (
    <CandyAuctionModalConfirmed>
      <CandyAuctionModalConfirmedHeader>
        <Tick height={"128px"} width={"128px"} color={"green"} />
        <div>{titleText}</div>
      </CandyAuctionModalConfirmedHeader>

      {descriptionText && (
        <CandyAuctionModalConfirmedDescription>
          {descriptionText}
        </CandyAuctionModalConfirmedDescription>
      )}

      <CandyAuctionModalNoticeCandyButton onClick={onClose}>
        Continue browsing
      </CandyAuctionModalNoticeCandyButton>
    </CandyAuctionModalConfirmed>
  );
};
