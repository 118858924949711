import styled from "styled-components"

export const NFTStatContainer = styled.div
`
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-evenly;

@media (max-width: 1000px){
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content:center;
    align-items:center;
}

`;

export const NFTStatInfo = styled.div
`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: center;

    @media (max-width: 1000px) {
        align-items: center;
    }

`

export const Divider = styled.span
`
border-left: 2px solid gray;
margin-right: 40px;

@media (max-width: 1000px) {
    margin: 0px;
}
`
