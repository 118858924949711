import { DiscordAlt, Instagram, Twitter } from '@styled-icons/boxicons-logos';
import styled from 'styled-components';

export const Box = styled.div`
padding: 5px 60px;
background: grey;

@media (max-width: 1000px) {
	padding: 10px 30px;
}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: 0 auto;
`

export const Row = styled.div`
display: flex;
flex-direction: row;
gap: 80px;
justify-content: center;

@media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
`;

export const NestedRow = styled(Row)`
@media screen and (max-width: 600px) {
    flex-direction: row;
    align-items: center;
}
`;

export const CopyText = styled.h2
    `
color: white;
textAlign: start;

@media screen and (max-width: 600px) {
    margin-bottom: 0;
}
`

export const SocialIcon = styled.a`
color: var(--social-media-color);
font-weight: bold;
outline: none;
text-decoration: none;
margin-top: auto;
margin-bottom: auto;
`;

export const DiscordIcon = styled(DiscordAlt)
    `
color: black;
:hover {
    color: white;
}
`

export const InstagramIcon = styled(Instagram)
    `
color: black;
:hover {
    color: white;
}
`

export const TwitterIcon = styled(Twitter)
    `
color: black;
:hover {
    color: white;
}
`
