import { ThemeProvider } from "@material-ui/core";
import { useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import "./App.css";

import { theme } from "./theme";
import { retrieveWalletProviders } from "./utils/walletUtils";
import Navbar from "./components/navbar/Navbar";
import AuctionComponent from "./components/auction/AuctionComponent";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/landing/LandingPage";
import BalanceContextProvider from "./components/balance/BalanceContextProvider";
import Footer from "./components/footer/Footer";
import { ModalProvider } from "styled-react-modal";

require("@solana/wallet-adapter-react-ui/styles.css");



const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const App = () => {
  // Custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(() => retrieveWalletProviders(), []);

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletModalProvider>
            <BalanceContextProvider>
              <BrowserRouter>
                <ModalProvider>
                  <Navbar />
                  <Routes>
                    <Route path="/auction" element={<AuctionComponent />} />
                    <Route path="/" element={<LandingPage />} />
                  </Routes>
                  <Footer />
                </ModalProvider>
              </BrowserRouter>
            </BalanceContextProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

export default App;
