import { CircularProgress } from "@material-ui/core";
import React, { ReactElement, useState } from "react";

import "./index.less";
import {
  CandyCard,
  CandyCardInfo,
  CandyCardInfoName,
  AuctionImage,
  AuctionImageContainer,
} from "./styled-components";

export interface CardProps {
  label?: ReactElement;
  onClick?: () => void;
  name?: string;
  footer?: ReactElement;
}

export const Card: React.FC<CardProps> = ({
  onClick,
  name,
  footer,
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <CandyCard onClick={onClick}>
      <AuctionImageContainer>
        {loading && (<CircularProgress/>)}
        <AuctionImage onLoad={() => setLoading(false)} alt={name} src={"FOREO_LUNA_4_special_Auction_page.gif"} />
      </AuctionImageContainer>
      <CandyCardInfo>
        <CandyCardInfoName>{name}</CandyCardInfoName>
        {footer}
      </CandyCardInfo>
    </CandyCard>
  );
};
