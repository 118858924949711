import Button from '@material-ui/core/Button';
import styled from "styled-components";

export const CandyAuctionModalDetail = styled.div
    `
    display: flex;
    flex-direction:column;
    align-items: center;
    overflow-y: scroll;
`;

export const CandyAuctionModalFormItem = styled.div
    `
    display:flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

export const CandyAuctionModalDetailContainer = styled.div
    `
    display: flex;
    flex-direction: row;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const CandyAuctionModalCountdown = styled.div
    `
    text-align: left;
`;

export const CandyCountdown = styled(CandyAuctionModalCountdown)
    `
    background: @linear-background;
    color: #fff;
    width: fit-content;
    padding: 8px 14px;
    border-radius: 12px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
`;

export const CandyCountdownEnded = styled(CandyCountdown)
    `
    background: grey;
`;

export const CandyCountdownNotStarted = styled(CandyCountdown)
    `
    background: gray;
`;

export const CandyAuctionModalPrompt = styled.div
    `
    margin-bottom: 15px;
    color: gray;
    text-align: left;
    font-size: 14px;
    display: block;
`;

export const CandyAuctionModalNotice = styled.div
    `
    display: flex;
    flex-direction: column;
    color: black;
    gap: 20px;
    font-weight: 600;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: rgba(244, 237, 254, 0.2);
    font-size: 14px;
    margin: auto;
    margin-bottom: 30px;
    text-align: center;
    @media screen and (max-width: 600px) {
        padding: 50px;
    }
`

export const CandyAuctionModalNoticeCandyButton = styled(Button)`
  display: block !important;
  margin: 0 auto !important;
  background-color: var(--title-text-color) !important;
  min-width: 180px !important;
  max-height: 50%;
  font-size: 1em !important;
`;

export const CandyAuctionModal = styled.div
    `
  display: flex;
  gap: 24px;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
`;

export const CandyAuctionModalCandyTitle = styled(CandyAuctionModal)
    `
    text-align: left;
    font-weight: bold;
    font-size: 24px;
`;

export const CandyAuctionModalThumbnail = styled(CandyAuctionModal)
    `
    flex:1;
    justify-content:center;
    margin: 12px;
    max-width: 80vh;
`;

export const CandyAuctionModalContainer = styled(CandyAuctionModal)
    `
    margin: 10px 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    @media (max-width: 1000px) {
        justify-content: center;
        align-items: center;
    }
`;

export const CandyAuctionModalControl = styled.div
    `
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-bottom: 5px;
    column-gap: 10px;

    justify-content: center;
    align-items: center;

    @media (max-width: 1000px) {
        flex-direction: column;
    }
`;

export const CandyAuctionModalControlCandyInputLabel = styled.h2
    `
    font-size: 14px;
    margin-bottom: 5px;

    @media(max-width: 1000px) {
        text-align: center;
    }

`;

export const CandyAuctionModalControlCandyInputLabelPrice = styled.h2
    `
    font-size: 1.8em;
    margin-bottom: 5px;
    text-align: start;
    color: black;

    @media (max-width: 1000px) {
        text-align: center;
    }
`;

export const CandyAuctionModalControlCandyInputPrice = styled.input
    `
    flex:1;
`;

export const CandyAuctionModalControlCandyValueField = styled(CandyAuctionModalControl)
    `
    flex:1;
`;

export const CandyAuctionModalControlCandyButton = styled(CandyAuctionModalControl)
    `
    padding: 8px 50px;
    border-radius: 32px;
    width: 220px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        margin-right: 10px;
    }
`;

export const CandyAuctionModalPrice = styled(CandyAuctionModal)
    `
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
`;

export const CandyAuctionModalConfirmed = styled(CandyAuctionModal)
    `
    flex-direction: column;
    padding: 30px;
`;

export const CandyAuctionModalConfirmedFlex = styled(CandyAuctionModalConfirmed)
    `
    display: flex;
    flex-flow: row wrap;
    row-gap: 24px;
    column-gap: 16px;
    > * {
    width: calc((100% - 16px) / 2);
    }
`;

export const CandyAuctionModalConfirmedItem = styled(CandyAuctionModalConfirmed)
    `
    text-align: left;
`;


export const CandyAuctionModalConfirmedHeader = styled(CandyAuctionModalConfirmed)
    `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-top: 20px;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
`;

export const CandyAuctionModalConfirmedDescription = styled(CandyAuctionModalConfirmed)
    `
    font-weight: 600;
    font-size: 16px;
`;

export const CandyAuctionModalConfirmedContainer = styled(CandyAuctionModalConfirmed)
    `
    width: 100%;
    display: flex;
    align-items: center;
    margin: 24px 0;

    p {
        margin-bottom: 4px;
    }
`;

export const CandyAuctionModalConfirmedContent = styled(CandyAuctionModalConfirmed)
    `
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    flex: 1;
`;

export const CandyAuctionModalConfirmedContentName = styled(CandyAuctionModalConfirmedContent)
    `
    text-align: left;
    font-size: 16px;
    font-weight: bold;
`;

export const CandyAuctionModalConfirmedContentTicker = styled(CandyAuctionModalConfirmedContent)
    `
    text-align: left;
    font-size: 14px;
`;

export const CandyAuctionModalConfirmedThumbnail = styled(CandyAuctionModalConfirmed)
    `
    width: 100px;
    height: 100px;
    margin-right: 20px;
`;

export const CandyAuctionModalConfirmedHr = styled(CandyAuctionModalConfirmed)
    `
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 24px;
`;

export const CandyAuctionModalConfirmedCandyButton = styled(CandyAuctionModalConfirmed)
    `
    width: 100%;
    padding: 8px 0;
    text-align: center;
    margin-top: 40px;
`;

export const CandyAuctionModalBidInfoContainer = styled.div
    `
    display:flex;
    flex-direction: column;
`

export const CandyAuctionModalControlBidInputContainer = styled.div
    `
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`
