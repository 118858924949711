import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import confetti from "canvas-confetti";
import * as anchor from "@project-serum/anchor";
import {
  Commitment,
  Connection,
  Transaction,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import Countdown from "react-countdown";
import { CircularProgress, Card } from "@material-ui/core";
import { toDate } from "../../utils";
import { MintButton } from "../../MintButton";
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
  SetupState,
} from "../../candy-machine";
import MessageComponent, {
  MetaplexError,
  MetaplexErrorType,
} from "../message/MessageComponent";
import { useWallet } from "@solana/wallet-adapter-react";
import {
  MintButtonContainer,
  MainContainer,
  NFTImageContainer,
  CopywriteContainer,
  SolExplorerLink,
  Image,
  BorderLinearProgress,
  Video,
} from "./styled-components";
import GoLiveDateCounter from "../date-counter/GoLiveDateCounter";
import BalanceContext from "../../contexts/BalanceContext";
import { ConnectButton, ConnectButtonSecondary } from "../wallet/styles";
import { TextTrademarkSpan } from "./styled-components";
import { TrademarkSpan } from "../header-image/styled-components";
import { HeadingTrademarkSpan } from "../../pages/landing/LandingPageAuctionCard";

const cluster = process.env.REACT_APP_SOLANA_NETWORK!.toString();
const decimals = process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS
  ? +process.env.REACT_APP_SPL_TOKEN_TO_MINT_DECIMALS!.toString()
  : 9;

export interface NFTInfoCardProps {
  candyMachineId?: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  rpcHost: string;
  network: WalletAdapterNetwork;
  index: number;
}

const NFTInfoCard = (props: NFTInfoCardProps) => {
  const balanceContext = useContext(BalanceContext);

  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [isActive, setIsActive] = useState(false); // true when countdown completes or whitelisted
  const [solanaExplorerLink, setSolanaExplorerLink] = useState<string>("");
  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [payWithSplToken, setPayWithSplToken] = useState(false);
  const [price, setPrice] = useState(0);
  const [isEnded, setIsEnded] = useState(false);

  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [setupTxn, setSetupTxn] = useState<SetupState>();

  const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();

  const solFeesEstimation = 0.012; // approx of account creation fees

  const [error, setError] = useState<MetaplexError | undefined>();

  const setMetaplexError = (
    errorType: MetaplexErrorType,
    message: string | undefined | null = null
  ) => {
    setError({
      type: errorType,
      message: message ? message : "",
    });
  };

  const wallet = useWallet();

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  useEffect(() => {
    (async () => {
      if (anchorWallet) {
        const balance = await props.connection.getBalance(
          anchorWallet!.publicKey
        );
        balanceContext.updateBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [anchorWallet, props.connection]);

  const refreshCandyMachineState = useCallback(
    async (commitment: Commitment = "confirmed") => {
      if (!anchorWallet) {
        return;
      }

      const connection = new Connection(props.rpcHost, commitment);

      if (props.candyMachineId) {
        try {
          const cndy = await getCandyMachineState(
            anchorWallet,
            props.candyMachineId,
            connection
          );

          setCandyMachine(cndy);
          setItemsAvailable(cndy.state.itemsAvailable);
          setItemsRemaining(cndy.state.itemsRemaining);
          setItemsRedeemed(cndy.state.itemsRedeemed);

          var divider = 1;
          if (decimals) {
            divider = +("1" + new Array(decimals).join("0").slice() + "0");
          }

          // detect if using spl-token to mint
          if (cndy.state.tokenMint) {
            setPayWithSplToken(true);
            setPrice(cndy.state.price.toNumber() / divider);
          } else {
            setPrice(cndy.state.price.toNumber() / LAMPORTS_PER_SOL);
          }

          // end the mint when amount is reached
          if (cndy?.state.endSettings?.endSettingType.amount) {
            let limit = Math.min(
              cndy.state.endSettings.number.toNumber(),
              cndy.state.itemsAvailable
            );
            setItemsAvailable(limit);
            if (cndy.state.itemsRedeemed < limit) {
              setItemsRemaining(limit - cndy.state.itemsRedeemed);
            } else {
              setItemsRemaining(0);
              cndy.state.isSoldOut = true;
              setIsEnded(true);
            }
          } else {
            setItemsRemaining(cndy.state.itemsRemaining);
          }

          if (cndy.state.isSoldOut) setIsActive(false);

          const [collectionPDA] = await getCollectionPDA(props.candyMachineId);
          const collectionPDAAccount = await connection.getAccountInfo(
            collectionPDA
          );

          const txnEstimate =
            892 +
            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
            (cndy.state.tokenMint ? 66 : 0) +
            (cndy.state.whitelistMintSettings ? 34 : 0) +
            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
            (cndy.state.gatekeeper ? 33 : 0) +
            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

          setNeedTxnSplit(txnEstimate > 1230);
        } catch (e) {}
      } else {
        setMetaplexError(MetaplexErrorType.CANDY_MACHINE);
      }
    },
    [
      anchorWallet,
      props.candyMachineId,
      props.rpcHost,
      isEnded,
      props.connection,
    ]
  );

  const renderGoLiveDateCounter = ({ days, hours, minutes, seconds }: any) => {
    return (
      <GoLiveDateCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  };

  function displaySuccess(mintPublicKey: any, qty: number = 1): void {
    let remaining = itemsRemaining - qty;
    setItemsRemaining(remaining);
    setIsSoldOut(remaining === 0);
    setSetupTxn(undefined);
    setItemsRedeemed(itemsRedeemed + qty);
    if (
      !payWithSplToken &&
      balanceContext.balance &&
      balanceContext.balance > 0
    ) {
      balanceContext.updateBalance(
        balanceContext.balance - price * qty - solFeesEstimation
      );
    }
    setSolanaExplorerLink(
      cluster === "devnet" || cluster === "testnet"
        ? "https://solscan.io/token/" + mintPublicKey + "?cluster=" + cluster
        : "https://solscan.io/token/" + mintPublicKey
    );
    setIsMinting(false);
    throwConfetti();
  }

  function throwConfetti(): void {
    confetti({
      particleCount: 400,
      spread: 70,
      origin: { y: 0.6 },
    });
  }

  const onMint = async (
    beforeTransactions: Transaction[] = [],
    afterTransactions: Transaction[] = []
  ) => {
    try {
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        setIsMinting(true);
        let setupMint: SetupState | undefined;
        if (needTxnSplit && setupTxn === undefined) {
          setMetaplexError(MetaplexErrorType.VALIDATE_ACCOUNT_SETUP);
          setupMint = await createAccountsForMint(
            candyMachine,
            wallet.publicKey
          );
          let status: any = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              props.txTimeout,
              props.connection,
              true
            );
          }
          if (status && !status.err) {
            setSetupTxn(setupMint);
            setMetaplexError(MetaplexErrorType.SETUP_TRANSACTIION_SUCCESS);
          } else {
            setMetaplexError(MetaplexErrorType.MINT_FAIL);
            return;
          }
        }

        const setupState = setupMint ?? setupTxn;
        const mint = setupState?.mint ?? anchor.web3.Keypair.generate();
        let mintResult = await mintOneToken(
          candyMachine,
          wallet.publicKey,
          mint,
          beforeTransactions,
          afterTransactions,
          setupState
        );

        let status: any = { err: true };
        let metadataStatus = null;
        if (mintResult) {
          status = await awaitTransactionSignatureConfirmation(
            mintResult.mintTxId,
            props.txTimeout,
            props.connection,
            true
          );

          metadataStatus =
            await candyMachine.program.provider.connection.getAccountInfo(
              mintResult.metadataKey,
              "processed"
            );
          console.log("Metadata status: ", !!metadataStatus);
        }

        if (status && !status.err && metadataStatus) {
          setMetaplexError(MetaplexErrorType.MINT_SUCCESS);
          displaySuccess(mint.publicKey);
          refreshCandyMachineState("processed");
        } else if (status && !status.err) {
          setMetaplexError(MetaplexErrorType.MINT_LIKELY_FAIL);
          refreshCandyMachineState();
        } else {
          setMetaplexError(MetaplexErrorType.MINT_FAIL);
          refreshCandyMachineState();
        }
      }
    } catch (error: any) {
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (!error.message) {
          message = "Transaction Timeout! Please try again.";
        } else if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }
      setMetaplexError(MetaplexErrorType.MESSAGE, message);
    } finally {
      setIsMinting(false);
    }
  };

  useEffect(() => {
    refreshCandyMachineState();
  }, [
    anchorWallet,
    props.candyMachineId,
    props.connection,
    isEnded,
    refreshCandyMachineState,
  ]);

  const renderMintContainer = () => {
    if (!isEnded && !isActive && !candyMachine)
      return (
        <MintButtonContainer>
          <CircularProgress />
        </MintButtonContainer>
      );

    if (isEnded) return <MintButtonContainer>Mint ended</MintButtonContainer>;

    if (!isActive) {
      return (
        <MintButtonContainer>
          <Countdown
            date={toDate(candyMachine?.state.goLiveDate)}
            onMount={({ completed }) => completed && setIsActive(!isEnded)}
            onComplete={() => {
              setIsActive(!isEnded);
            }}
            renderer={renderGoLiveDateCounter}
          />
        </MintButtonContainer>
      );
    }

    return (
      <MintButtonContainer>
        {wallet && isActive && (
          <h5 style={{ margin: "20px" }}>
            TOTAL MINTED : {itemsRedeemed} / {itemsAvailable}
          </h5>
        )}

        {wallet && price && <h5>PRICE: {price} SOL</h5>}

        <MintButton
          candyMachine={candyMachine}
          isMinting={isMinting}
          isActive={isActive}
          isEnded={isEnded}
          isSoldOut={isSoldOut}
          onMint={onMint}
        />
      </MintButtonContainer>
    );
  };

  return (
    <main>
      <MainContainer
        style={{
          flexDirection: props.index % 2 == 0 ? "row" : "row-reverse",
        }}
      >
        <NFTImageContainer>
          <Video muted autoPlay loop>
            <source src="FOREO_LUNA4_NFT_1000x700px.mp4" type="video/mp4" />
          </Video>
        </NFTImageContainer>
        <CopywriteContainer style={{ marginTop: 60 }}>
          <h1>Our LUNA<HeadingTrademarkSpan>&trade;</HeadingTrademarkSpan>s</h1>
          <div style={{ textAlign: "center", padding: 40 }}>
            <p>
              Each LUNA<TextTrademarkSpan>&trade;</TextTrademarkSpan> in our
              range comes with its own purpose, plumped up and ready to turn
              your skin into its best version yet.
            </p>
            <p>
              That's why we felt creating a number of various NFTs would be the
              best approach to helping as many different charities as we can -
              one donation at a time.
            </p>
          </div>
          <br />
          {!anchorWallet && (
            <ConnectButtonSecondary>SELECT WALLET</ConnectButtonSecondary>
          )}
          {anchorWallet && renderMintContainer()}
          <br />
          {wallet && isActive && solanaExplorerLink && (
            <SolExplorerLink href={solanaExplorerLink} target="_blank">
              View on Solscan
            </SolExplorerLink>
          )}
        </CopywriteContainer>
      </MainContainer>
      <MessageComponent e={error} />
    </main>
  );
};

export default NFTInfoCard;
