import React, { useState, useEffect } from "react";
import { CandyShop } from "../../candy-shop-sdk/src";
import { Auction, AuctionStatus } from "@liqnft/candy-shop-types";
import { AnchorWallet } from "@solana/wallet-adapter-react";
import { AuctionModal } from "./AuctionModal";
import { Countdown } from "./Countdown";
import { Price } from "./Price";
import { Card } from "./Card";
import {
  CandyCardFooter,
  CandyCardStat,
  PlaceBidButton,
} from "./styled-components";

interface AuctionCardProps {
  auction: Auction;
  candyShop: CandyShop;
  wallet?: AnchorWallet;
  walletConnectComponent: React.ReactElement;
}

interface LastBidInfo {
  price: string | undefined;
  title: string;
}

export const AuctionCard: React.FC<AuctionCardProps> = ({
  auction,
  candyShop,
  wallet,
  walletConnectComponent,
}) => {
  const [selected, setSelected] = useState<any>();
  const [lastBid, setLastBid] = useState<LastBidInfo>();

  useEffect(() => {
    if (auction.status === AuctionStatus.CREATED) {
      setLastBid({ price: auction.startingBid, title: "Starting bid" });
    } else if (auction.status === AuctionStatus.STARTED) {
      if (auction.highestBidPrice) {
        setLastBid({ price: auction.highestBidPrice, title: "Current bid" });
      } else {
        setLastBid({ price: auction.startingBid, title: "Starting bid" });
      }
    } else if (
      auction.status === AuctionStatus.COMPLETE ||
      auction.status === AuctionStatus.CANCELLED ||
      auction.status === AuctionStatus.EXPIRED
    ) {
      setLastBid({ price: auction.highestBidPrice, title: "Winning bid" });
    }
  }, [auction, wallet]);

  return (
    <div>
      <Card
        key={auction.auctionAddress}
        name={auction.name}
        footer={
          <CandyCardFooter>
            <CandyCardStat>
              {lastBid?.title}:{" "}
              <Price value={lastBid?.price} candyShop={candyShop} />
            </CandyCardStat>
            <CandyCardStat
              as={"h5"}
              style={{ fontSize: "1rem", maxWidth: "60%" }}
            >
              <Countdown
                start={Number(auction.startTime)}
                end={Number(auction.startTime) + Number(auction.biddingPeriod)}
                status={auction.status}
              />
            </CandyCardStat>

            {auction.status == AuctionStatus.STARTED && (
              <PlaceBidButton onClick={() => setSelected(true)}>
                Place Bid
              </PlaceBidButton>
            )}

            {auction.status != AuctionStatus.STARTED && (
              <PlaceBidButton onClick={() => setSelected(true)}>
                Auction Info
              </PlaceBidButton>
            )}
          </CandyCardFooter>
        }
      />
      {candyShop && (
        <AuctionModal
          isOpen={selected}
          opacity={selected ? 1 : 0}
          auction={auction}
          onClose={() => setSelected(false)}
          wallet={wallet}
          candyShop={candyShop}
          walletConnectComponent={walletConnectComponent}
        />
      )}
    </div>
  );
};
