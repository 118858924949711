import React, { useEffect, useState } from "react";
import { web3 } from "@project-serum/anchor";

import { NftStat } from "../NftStat";
import { ExplorerLink } from "../ExplorerLink";
import { Countdown } from "../Countdown";
import { Price } from "../Price";

import {
  Auction,
  AuctionBid,
  SingleBase,
  AuctionStatus,
  BidStatus,
} from "@liqnft/candy-shop-types";

import {
  CandyAuctionModalBidInfoContainer,
  CandyAuctionModalCandyTitle,
  CandyAuctionModalContainer,
  CandyAuctionModalControl,
  CandyAuctionModalControlBidInputContainer,
  CandyAuctionModalControlCandyInputLabel,
  CandyAuctionModalControlCandyInputLabelPrice,
  CandyAuctionModalControlCandyInputPrice,
  CandyAuctionModalControlCandyValueField,
  CandyAuctionModalCountdown,
  CandyAuctionModalDetail,
  CandyAuctionModalDetailContainer,
  CandyAuctionModalFormItem,
  CandyAuctionModalNotice,
  CandyAuctionModalNoticeCandyButton,
  CandyAuctionModalPrompt,
  CandyAuctionModalThumbnail,
} from "./styled-components";
import { CandyCardStat } from "../styled-components";
import { AuctionImage } from "../Card/styled-components";
import { CandyModalContentCloseButtonContainer } from "../Modal/styled-components";
import { Close } from "styled-icons/material";
import { CircularProgress } from "@material-ui/core";
import {
  CandyShop,
  fetchAuctionBidByWalletAddress,
} from "../../../candy-shop-sdk/src";

const Logger = "CandyShopUI/AuctionModalDetail";
export interface AuctionModalDetailProps {
  auction: Auction;
  placeBid: (price: number) => void;
  onWithdrew: () => void;
  onClose: () => void;
  walletPublicKey: web3.PublicKey | undefined;
  walletConnectComponent: React.ReactElement;
  candyShop: CandyShop;
}

export const AuctionModalDetail: React.FC<AuctionModalDetailProps> = ({
  auction,
  placeBid,
  onWithdrew,
  onClose,
  walletPublicKey,
  walletConnectComponent,
  candyShop,
}) => {
  const [bidInfo, setBidInfo] = useState<AuctionBid | null>(null);
  const [price, setPrice] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    if (!walletPublicKey) return;

    fetchAuctionBidByWalletAddress(
      auction.auctionAddress,
      walletPublicKey.toString()
    )
      .then((res: SingleBase<AuctionBid>) => {
        if (res.success) {
          setBidInfo(res.result);
          console.log(
            `${Logger}: fetchAuctionBidByWalletAddress success=`,
            res.result
          );
          console.log(
            `${Logger}: fetchAuctionBidAPI BidStatus=`,
            mappedBidStatusString(res.result.status)
          );
        } else {
          console.log(
            `${Logger}: fetchAuctionBidAPI failed, ${walletPublicKey.toString()} has not placed any bid yet`
          );
        }
      })
      .catch((error: any) => {
        console.log(`${Logger}: fetchAuctionBidAPI failed, error=`, error);
      });
  }, [auction, walletPublicKey]);

  const isEnableBuyNow = Boolean(auction.buyNowPrice);

  const minNextBid = auction.highestBidPrice
    ? Number(auction.highestBidPrice) + Number(auction.tickSize)
    : Number(auction.startingBid);
  const acceptNextBid =
    !isEnableBuyNow ||
    (isEnableBuyNow && minNextBid < Number(auction.buyNowPrice));

  const PlaceBidButton = walletPublicKey ? (
    <CandyAuctionModalNoticeCandyButton
      disabled={Boolean(!price)}
      onClick={() => price && placeBid(Number(price))}
    >
      Place Bid
    </CandyAuctionModalNoticeCandyButton>
  ) : (
    walletConnectComponent
  );

  const ModalAlertElement = () => {
    if (!bidInfo) return null;

    if (
      auction.highestBidBuyer &&
      auction.highestBidBuyer === walletPublicKey?.toString() &&
      bidInfo.status !== BidStatus.WON
    ) {
      return (
        <CandyAuctionModalNotice>
          Congratulations, you are currently the highest bidder!
        </CandyAuctionModalNotice>
      );
    }

    if (
      auction.highestBidBuyer &&
      auction.highestBidBuyer === walletPublicKey?.toString() &&
      bidInfo.status === BidStatus.WON
    ) {
      return (
        <CandyAuctionModalNotice>
          Congratulations, you have won the auction!
        </CandyAuctionModalNotice>
      );
    }

    if (bidInfo.status !== BidStatus.WITHDRAWN) {
      return (
        <CandyAuctionModalNotice>
          <CandyAuctionModalNoticeCandyButton
            style={{ marginRight: 15 }}
            onClick={onWithdrew}
          >
            Retrieve Funds
          </CandyAuctionModalNoticeCandyButton>

          {auction.status === AuctionStatus.STARTED
            ? "You have been outbid! Retrieve your funds here or place a higher bid below."
            : "You have been outbid! Retrieve your funds here"}
        </CandyAuctionModalNotice>
      );
    }

    return null;
  };

  const mappedBidStatusString = (bidStatus: BidStatus) => {
    switch (bidStatus) {
      case BidStatus.LOST:
        return "LOST";
      case BidStatus.OPEN:
        return "OPEN";
      case BidStatus.WITHDRAWN:
        return "WITHDRAWN";
      case BidStatus.WON:
        return "WON";
      default:
        console.log(`${Logger}: Invalid BitStatus ${bidStatus}`);
    }
  };

  let auctionContent: React.ReactElement | null = null;
  if (auction.status === AuctionStatus.CREATED) {
    auctionContent = (
      <CandyAuctionModalFormItem>
        <CandyAuctionModalControlCandyInputLabel>
          STARTING BID
        </CandyAuctionModalControlCandyInputLabel>
        <CandyAuctionModalControlCandyInputLabelPrice>
          <Price value={auction.startingBid} candyShop={candyShop} />
        </CandyAuctionModalControlCandyInputLabelPrice>
      </CandyAuctionModalFormItem>
    );
  } else if (auction.status === AuctionStatus.STARTED) {
    auctionContent = (
      <>
        <CandyAuctionModalFormItem>
          <CandyAuctionModalBidInfoContainer>
            <CandyAuctionModalControlCandyInputLabel>
              {auction.highestBidPrice ? "CURRENT BID" : "STARTING BID"}
            </CandyAuctionModalControlCandyInputLabel>
            <CandyAuctionModalControlCandyInputLabelPrice>
              <Price
                value={
                  auction.highestBidPrice
                    ? auction.highestBidPrice
                    : auction.startingBid
                }
                candyShop={candyShop}
              />
            </CandyAuctionModalControlCandyInputLabelPrice>
          </CandyAuctionModalBidInfoContainer>

          {acceptNextBid ? (
            <>
              <CandyAuctionModalControl>
                <CandyAuctionModalControlBidInputContainer>
                  <CandyAuctionModalControlCandyInputLabel>
                    Enter your bid
                  </CandyAuctionModalControlCandyInputLabel>
                  <CandyAuctionModalControlCandyInputPrice
                    placeholder={`${String(
                      minNextBid / candyShop.baseUnitsPerCurrency
                    )}+ ${candyShop?.currencySymbol}`}
                    min={minNextBid / candyShop.baseUnitsPerCurrency}
                    onChange={(e: any) => setPrice(e.target.value)}
                    type="number"
                    value={price === undefined ? "" : price}
                  />
                  <CandyAuctionModalPrompt>
                    Place bid of{" "}
                    <Price value={minNextBid} candyShop={candyShop} /> or more
                  </CandyAuctionModalPrompt>
                </CandyAuctionModalControlBidInputContainer>

                {PlaceBidButton}
              </CandyAuctionModalControl>
            </>
          ) : (
            <CandyAuctionModalPrompt>
              Maximum bid reached. Buy now to win this auction
            </CandyAuctionModalPrompt>
          )}
        </CandyAuctionModalFormItem>
      </>
    );
  } else if (
    auction.status === AuctionStatus.COMPLETE ||
    auction.status === AuctionStatus.EXPIRED ||
    auction.status === AuctionStatus.CANCELLED
  ) {
    auctionContent = (
      <>
        <CandyAuctionModalFormItem>
          <CandyAuctionModalControlCandyInputLabel>
            WINNING BID
          </CandyAuctionModalControlCandyInputLabel>
          <CandyAuctionModalControlCandyInputLabelPrice>
            <Price
              value={auction.highestBidPrice}
              candyShop={candyShop}
              emptyValue="No winner"
            />
          </CandyAuctionModalControlCandyInputLabelPrice>
        </CandyAuctionModalFormItem>

        {auction.highestBidBuyer && (
          <CandyCardStat>
            <CandyAuctionModalControlCandyInputLabel>
              WINNER
            </CandyAuctionModalControlCandyInputLabel>
            <CandyAuctionModalControlCandyValueField>
              <ExplorerLink
                type="address"
                address={auction.highestBidBuyer}
                source={candyShop.explorerLink}
                env={candyShop.env}
              />
            </CandyAuctionModalControlCandyValueField>
          </CandyCardStat>
        )}
      </>
    );
  }

  return (
    <CandyAuctionModalDetail>
        <>
          <CandyModalContentCloseButtonContainer>
            <div style={{ flex: 6 }} />
            <Close
              color="black"
              onClick={onClose}
              style={{ flex: 1, cursor: "pointer", marginRight: "10px" }}
            />
          </CandyModalContentCloseButtonContainer>
          {ModalAlertElement()}

          <CandyAuctionModalDetailContainer>
            <CandyAuctionModalThumbnail>
              {loading && <CircularProgress style={{margin:"50px"}} />}

              <AuctionImage
                onLoad={() => setLoading(false)}
                src={auction.image || ""}
                alt={auction.name}
                style={{display: loading ? "none" : "flex"}}
              />
              {!auction && <CircularProgress />}
            </CandyAuctionModalThumbnail>
            <CandyAuctionModalContainer>
              <CandyAuctionModalCountdown>
                <Countdown
                  start={Number(auction.startTime)}
                  end={
                    Number(auction.startTime) + Number(auction.biddingPeriod)
                  }
                  status={auction.status}
                />
              </CandyAuctionModalCountdown>

              <CandyAuctionModalCandyTitle>
                {auction?.name}
              </CandyAuctionModalCandyTitle>

              {auctionContent}

              <NftStat
                owner={auction.sellerAddress}
                tokenMint={auction.tokenMint}
                candyShop={candyShop}
              />
            </CandyAuctionModalContainer>
          </CandyAuctionModalDetailContainer>
        </>
    </CandyAuctionModalDetail>
  );
};
