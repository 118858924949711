import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const AuctionContainer = styled(Paper)`
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    justify-content: center;
    min-height: 100vh;
    background-image: url(auction_page_bg.png) !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const CandyContainer = styled.div
`
    display: flex;
    align-items: center;
    justify-content:center;
    min-height: 600px
`
