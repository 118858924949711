import React, { useEffect } from "react";
import { Close } from "styled-icons/material";

import "./index.less";
import {
  CandyModal,
  CandyModalContent,
} from "./styled-components";

export interface ModalProps {
  children: any;
  onCancel: (...args: any) => void;
  closeOnClickOutside?: boolean;
  isOpen: boolean;
  opacity: number;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  onCancel,
  closeOnClickOutside = true,
  isOpen,
  opacity,
}: ModalProps) => {
  useEffect(() => {
    window.addEventListener("click", onCancel);
    return window.removeEventListener("click", onCancel);
  }, [onCancel]);

  return (
    <CandyModal
      isOpen={isOpen}
      backgroundProps={{ opacity }}
      onBackgroundClick={() => closeOnClickOutside && onCancel()}
    >
      <CandyModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </CandyModalContent>
    </CandyModal>
  );
};
