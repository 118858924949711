import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { getCandyMachineId } from "../../utils/candyMachineUtils";
import { AlertState } from "../../utils";

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const candyMachineId = getCandyMachineId();

export enum MetaplexErrorType {
  GENERAL,
  CANDY_MACHINE,
  MINT_FAIL,
  MINT_SUCCESS,
  SETUP_TRANSACTIION_SUCCESS,
  VALIDATE_ACCOUNT_SETUP,
  MINT_LIKELY_FAIL,
  MESSAGE,
}

export interface MetaplexError {
  type: MetaplexErrorType;
  message: string | undefined | null;
}

interface MessageComponentProps {
  e: MetaplexError | Error | undefined;
}

const MessageComponent = (props: MessageComponentProps) => {
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  useEffect(() => {
    if (props.e instanceof Error) {
      let e = props.e;
      if (e.message === `Account does not exist ${candyMachineId}`) {
        setAlertState({
          open: true,
          message: `Couldn't fetch candy machine state from candy machine with address: ${candyMachineId}, using rpc: ${rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
          severity: "error",
          hideDuration: null,
        });
      } else if (e.message.startsWith("failed to get info about account")) {
        setAlertState({
          open: true,
          message: `Couldn't fetch candy machine state with rpc: ${rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
          severity: "error",
          hideDuration: null,
        });
      } else {
        setAlertState({
          open: true,
          message: `${e}`,
          severity: "error",
          hideDuration: null,
        });
      }
    } else {
      switch (props.e?.type) {
        case MetaplexErrorType.MINT_FAIL:
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
          break;
        case MetaplexErrorType.CANDY_MACHINE:
          setAlertState({
            open: true,
            message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
            severity: "error",
            hideDuration: null,
          });
          break;
        case MetaplexErrorType.SETUP_TRANSACTIION_SUCCESS:
          setAlertState({
            open: true,
            message:
              "Setup transaction succeeded! You can now validate mint transaction",
            severity: "info",
          });
          break;
        case MetaplexErrorType.VALIDATE_ACCOUNT_SETUP:
          setAlertState({
            open: true,
            message: "Please validate account setup transaction",
            severity: "info",
          });
          break;
        case MetaplexErrorType.MINT_SUCCESS:
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
          break;
        case MetaplexErrorType.MINT_LIKELY_FAIL:
          setAlertState({
            open: true,
            message:
              "Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.",
            severity: "error",
            hideDuration: 8000,
          });
          break;
        case MetaplexErrorType.MESSAGE:
          setAlertState({
            open: true,
            message: props.e.message ? props.e.message : "",
            severity: "error",
          });
          break;
      }
    }
  }, [props.e]);

  return (
    <Snackbar
      open={alertState.open}
      autoHideDuration={6000}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
};

export default MessageComponent;
