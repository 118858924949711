import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

export const CREATOR_ADDRESS = process.env.REACT_APP_CREATOR_ADDRESS;
export const TREASURY_MINT = "So11111111111111111111111111111111111111112";
export const CANDY_SHOP_V2_PROGRAM_ID = process.env.REACT_APP_CANDY_SHOP_V2_ID;

const NETWORK = process.env.REACT_APP_SOLANA_NETWORK

export const DEFAULT_FORM_CONFIG = {
  creatorAddress: CREATOR_ADDRESS,
  treasuryMint: TREASURY_MINT,
  programId: CANDY_SHOP_V2_PROGRAM_ID,
  network: NETWORK == "devnet" ? WalletAdapterNetwork.Devnet : WalletAdapterNetwork.Mainnet,
  settings: JSON.stringify({
    mainnetConnectionUrl: process.env.REACT_APP_SOLANA_RPC_HOST
  })
};
