import { useWallet } from "@solana/wallet-adapter-react";
import { useContext, useEffect } from "react";
import BalanceContext from "../../contexts/BalanceContext";
import { ConnectButton, Wallet, WalletAmount, WalletContainer } from "./styles";

export const WalletContainerComponent = () => {
  const wallet = useWallet();
  const balanceContext = useContext(BalanceContext);

  useEffect(() => {
    if (wallet.disconnecting) {
      balanceContext.updateBalance(0);
      window.location.reload();
    }
  }, [wallet.disconnecting]);

  return (
    <WalletContainer>
      <Wallet>
        {wallet && (
          <WalletAmount>
            {balanceContext.balance != 0 && (
              <span style={{ padding: "10px", paddingTop: "15px" }}>
                {balanceContext.balance.toFixed(4)} SOL
              </span>
            )}
            <ConnectButton />
          </WalletAmount>
        )}

        {!wallet && <ConnectButton>Connect wallet</ConnectButton>}
      </Wallet>
    </WalletContainer>
  );
};
