import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { getPhantomWallet, getSlopeWallet, getSolflareWallet, getSolflareWebWallet, getSolletWallet, getSolletExtensionWallet, getSolongWallet, getLedgerWallet, getSafePalWallet } from "@solana/wallet-adapter-wallets";

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

export const retrieveWalletProviders = () =>
    [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getSolflareWebWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network }),
        getSolongWallet(),
        getLedgerWallet(),
        getSafePalWallet(),
    ]
