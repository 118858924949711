import { web3 } from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useEffect, useMemo, useState } from "react";
import { DEFAULT_FORM_CONFIG } from "./candyFormConfiguration";
import { CandyShop } from "../../external/liqnft/candy-shop-sdk/src";
import { AuctionContainer } from "./styled-components";
import { AuctionsOverride } from "./AuctionsOverride";
import { CandyShopDataValidator } from "../../external/liqnft/candy-shop/contexts/CandyShopDataValidator";
import { CircularProgress } from "@material-ui/core";
import { ConnectButton } from "../wallet/styles";
import { WalletContainerComponent } from "../wallet/WalletContainer";

const candyForm = DEFAULT_FORM_CONFIG;

export enum AuctionStatus {
  CREATED,
  STARTED,
  COMPLETE,
  CANCELLED,
  EXPIRED,
}

const AuctionComponent = () => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const wallet = useAnchorWallet();

  const candyShop = useMemo(() => {
    let candyShop: any = null;
    try {
      candyShop = new CandyShop({
        candyShopCreatorAddress: new web3.PublicKey(candyForm.creatorAddress!!),
        treasuryMint: new web3.PublicKey(candyForm.treasuryMint!!),
        candyShopProgramId: new web3.PublicKey(candyForm.programId!!),
        env: candyForm.network,
        settings: JSON.parse(candyForm.settings),
        isEnterprise: false,
      });
    } catch (err) {
      console.log(`CandyShop: create instance failed, error=`, err);
    }

    return candyShop;
  }, [candyForm]);

  useEffect(() => {
    const img = new Image();
    img.src = "auction_page_bg.png";
    img.onload = () => {
      setTimeout(() => {
        setImgLoaded(true);
      }, 1000)
    };
  }, []);

  return (
    <CandyShopDataValidator>
      <>
        {!imgLoaded && (
          <div style={{ display: "flex", alignItems: "center", justifyItems: "center", width: "100%", height: "100vh" }}>
            <CircularProgress style={{margin: "auto"}} />
          </div>
        )}

        {imgLoaded && (
          <AuctionContainer>
            <h1 style={{ marginTop: 60, textAlign: "center" }}>Auctions</h1>

            <AuctionsOverride
              candyShop={candyShop}
              wallet={wallet}
              walletConnectComponent={<WalletContainerComponent />}
            />
          </AuctionContainer>
        )}
      </>
    </CandyShopDataValidator>
  );
};

export default AuctionComponent;
