import NFTInfoCard from "../../components/nft-info-card/NFTInfoCard";
import { DEFAULT_TIMEOUT } from "../../connection";
import { getCandyMachineId } from "../../utils/candyMachineUtils";
import * as anchor from "@project-serum/anchor";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import HeaderImage from "../../components/header-image/HeaderImage";
import HeaderText from "./HeaderText";
import LandingPageAuctionCard from "./LandingPageAuctionCard";

const candyMachineId = getCandyMachineId();

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;

const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl("devnet")
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const LandingPage = () => {
  return (
    <div>
      <HeaderImage />
      <HeaderText />
      <NFTInfoCard
        candyMachineId={candyMachineId}
        connection={connection}
        txTimeout={DEFAULT_TIMEOUT}
        rpcHost={rpcHost}
        network={network}
        index={0}
      />
      <LandingPageAuctionCard index={1}/>
    </div>
  );
};

export default LandingPage;
