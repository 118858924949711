import styled from "styled-components"

export const CandyCard = styled.div
    `
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-right: 4%;
    margin-left: 4%;
    align-items: center;
    border-radius: 32px;
    padding: 20px;

    &.selected {
        border-color: black;
    }

`;

export const CandyCardInfo = styled.div
    `
    display:flex;
    flex-direction: column;
    color: black;
    justify-content:center;
    align-items: center;
`

export const CandyCardInfoName = styled.h2
    `
    min-height: 1.57em;
    padding: 0;
    margin: 0;
    margin-top: 30px;
    text-align: center;
`

export const AuctionImage = styled.img`
  border-radius: 7px;
  max-width: 100%;
`;

export const AuctionImageContainer = styled.div`
    display: flex;
    flex: 1;
    background-color: inherit;
`;
