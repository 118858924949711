import { Card } from "@material-ui/core";

interface GoLiveDateCounterProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const GoLiveDateCounter = ({
  days,
  hours,
  minutes,
  seconds,
}: GoLiveDateCounterProps) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "20px", flexWrap: "wrap" }}>
      {days > 0 && (
        <Card style={{ padding: "20px" }} elevation={1}>
          <h1 style={{ color: "white" }}>{days}</h1>Days
        </Card>
      )}

      <Card style={{ padding: "20px" }} elevation={1}>
        <h1 style={{ color: "white" }}>{hours}</h1>
        Hours
      </Card>
      <Card style={{ padding: "20px" }} elevation={1}>
        <h1 style={{ color: "white" }}>{minutes}</h1>Mins
      </Card>
      <Card style={{ padding: "20px" }} elevation={1}>
        <h1 style={{ color: "white" }}>{seconds}</h1>Secs
      </Card>
    </div>
  );
};

export default GoLiveDateCounter;
