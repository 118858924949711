import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const PlaceBidButton = styled(Button)`
  display: block !important;
  margin: 10px auto !important;
  background-color: var(--main-button-background) !important;
  min-width: 120px !important;
  font-size: 1em !important;
`;

export const CandyCardStat = styled.h4
    `
    text-align: center;
    padding:0;
    margin:0;
`;

export const CandyCardFooter = styled.div
    `
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    align-items: center;
    justify-content: center;
`;
