import React from "react";
import { ExplorerLink } from "../ExplorerLink";
import { CandyShop } from "../../../candy-shop-sdk/src";
import { shortenAddress } from "../../utils/helperFunc";
import { Divider, NFTStatContainer, NFTStatInfo } from "./styled-components";

export interface NftStatProps {
  tokenMint: string;
  edition?: number | string | null;
  owner?: string;
  sellerUrl?: string;
  candyShop: CandyShop;
}

export const NftStat: React.FC<NftStatProps> = ({
  tokenMint,
  edition,
  owner,
  sellerUrl,
  candyShop,
}) => {
  return (
    <NFTStatContainer>
      <NFTStatInfo>
        <div style={{ color: "var(--main-text-color)" }}>MINT ADDRESS</div>
        <div className="candy-value">
          <ExplorerLink
            type="address"
            address={tokenMint}
            source={candyShop.explorerLink}
            env={candyShop.env}
          />
        </div>
      </NFTStatInfo>

      {owner && (
        <NFTStatInfo>
          <div style={{ color: "var(--main-text-color)" }}>OWNER</div>
          <div className="candy-value">
            {sellerUrl ? (
              <a
                href={sellerUrl.replace("{{sellerAddress}}", owner)}
                target="_blank"
                rel="noreferrer noopener"
                title="Seller profile"
              >
                {shortenAddress(owner)}
              </a>
            ) : (
              <ExplorerLink
                type="address"
                address={owner}
                source={candyShop.explorerLink}
                env={candyShop.env}
              />
            )}
          </div>
        </NFTStatInfo>
      )}
    </NFTStatContainer>
  );
};
